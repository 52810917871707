import { Component, OnInit } from '@angular/core';
import { SideMenuService } from 'src/app/core/services/side-menu.service';
import { UserService } from 'src/app/core/services/user.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AuthFirebaseService } from '../../cross-profile/authentication/auth-firebase.service';

@Component({
    selector: 'app-main-administration',
    templateUrl: './main-administration.component.html',
    styleUrls: ['./main-administration.component.scss']
})
export class MainAdministrationComponent implements OnInit {

    constructor(
        private sideMenuService: SideMenuService,
        private notification: NotificationService,
        private auth: AuthFirebaseService,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.notification.requestPermission();
        this.auth.setUserSession(this.userService.loggedUser.id).subscribe(() => {
            this.auth.listenUserSessionChanged(this.userService.loggedUser.id);
        });
    }

    isCollapsed() {
        return this.sideMenuService.opened;
    }

    checkIfFinsteinHelper() {
        return this.notification.isFinsteinHelper();
    }

    get userLogged() {
        return this.userService.isLogged();
    }
}
