import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { AdministrationSideMenuModule } from '../../../core/components/administration-side-menu/administration-side-menu.module';
import { HeaderModule } from '../../../core/components/header/header.module';
import { NotificationsSideMenuModule } from '../../../shared/components/notifications-side-menu/notifications-side-menu.module';
import { SharedModule } from '../../../shared/shared.module';
import { AuthenticationModule } from '../../cross-profile/authentication/authentication.module';
import { MainAdministrationComponent } from './main-administration.component';
import { ProgressToasterModule } from 'src/app/shared/components/progress-toaster/progress-toaster.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HeaderModule,
        AuthenticationModule,
        SharedModule,
        MatSidenavModule,
        NotificationsSideMenuModule,
        AdministrationSideMenuModule,
        ProgressToasterModule
    ],
    declarations: [MainAdministrationComponent],
})
export class MainAdministrationModule {}
