import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { LanguageService } from 'src/app/core/services/language.service';
import {
    LANG_KEY_LOCAL_STORAGE_KEY,
    STATUS_PENDENT,
} from '../../../app.constants';
import { AuthFirebaseService } from '../../../modules/cross-profile/authentication/auth-firebase.service';
import { NotificationService } from '../../services/notification.service';
import { StorageService } from '../../services/storage.service';
import { MatDrawer } from '@angular/material/sidenav';
import { NotificationsSideMenuService } from '../../../shared/components/notifications-side-menu/notifications-side-menu.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CompaniesService } from '../../../modules/administration/company-leads/companies.service';
import { environment } from 'src/environments/environment';
import {MatDialog} from "@angular/material/dialog";
import {CookieDialogComponent} from "../../../shared/components/cookie-dialog/cookie-dialog.component";
import {CookieTypesEnum} from "../../../shared/enums/cookie-types.enum";
import {ChangePasswordDialogComponent} from "../../../shared/components/change-password-dialog/change-password-dialog.component";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent extends BaseComponent implements OnInit {
    languages: any[];
    language: string;
    selectedNotification: any;

    @ViewChild(MatDrawer, {static: false})
    drawer: MatDrawer;

    constructor(
        private auth: AuthFirebaseService,
        private afAuth: AngularFireAuth,
        private languageService: LanguageService,
        private storageService: StorageService,
        private notificationService: NotificationService,
        private notificationsSideMenuService: NotificationsSideMenuService,
        private companiesService: CompaniesService,
        private dialog: MatDialog,
    ) {
        super();
        this.languages = this.languageService.getAll();
        this.language = this.storageService.get(LANG_KEY_LOCAL_STORAGE_KEY);
        if (!this.language) {
            const browserLang = this.translateService.getBrowserLang();
            this.language = browserLang.match(/en|de/) ? browserLang : 'de';
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        // this.initLogoutObserver();
    }

    get loggedUser() {
        return this.userService.loggedUser;
    }

    changeLanguage() {
        if (this.isAllowedEnv()) {
            this.spinner.show();
            this.userService
                .updateLanguage(this.language)
                .then(() => {
                    this.languageService
                        .changeLanguage(this.language)
                        .subscribe(
                            () => {
                                this.spinner.hide();
                            },
                            () => {
                                this.spinner.hide();
                            }
                        );
                    this.storageService.save(LANG_KEY_LOCAL_STORAGE_KEY, this.language);
                })
                .catch((error) => {
                    this.spinner.hide();
                    this.notification.error('operations.language.update-error');

                    const message = error.error
                        ? error.error.message
                        : error.message;
                    if (message) {
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while updating languange in [userService.updateLanguage], details: ${ message }`
                        );
                    } else {
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while updating languange in [userService.updateLanguage], details: ${JSON.stringify(error)}`
                        );
                    }
                });
        } else {
            this.notification.error('operations.language.update-error');
        }
    }

    openNotificationsSidenav() {
        this.notificationsSideMenuService.toggle();
    }

    async logout() {
        this.spinner.show();
        this.auth.signOut().subscribe(() => {
            this.dialog.closeAll();
            this.spinner.hide();
            this.navigate(['/authentication']);
        });
    }

    getNotViewedNotifications() {
        return this.notificationService.getQtyNotViewedNotifications();
    }

    isPendentCompany() {
        return this.userService.loggedUser.companyStatus === STATUS_PENDENT;
    }

    openDialogChangePassword() {
        this.dialog.open(ChangePasswordDialogComponent, {
            panelClass: 'curved-modal',
            width: 'auto',
            height: 'auto',
        }).afterClosed().subscribe(res => {
            if (res) {
                this.auth.reauthenticateWithCredential(res);
            }
            this.notification.showToast(res?.code, res?.message);
        })
    }

    isActiveRoute(routeName: string): boolean {
        return this.router.url.includes(routeName);
    }

    hasAnyPermission(value: string | string[]) {
        return this.companiesService.hasAnyPermission(value);
    }

    /**
     * This method can never be unsubscribed, he allows us to logout user at all other tabs opened
     */
    initLogoutObserver() {
        // this.logoutSubscription = this.afAuth.user
        //     .pipe(map((user) => !!user))
        //     .subscribe(async (userIsPresent) => {
        //         console.log('test');
        //         if (!userIsPresent) {
        //             await this.logout();
        //             this.logoutSubscription.unsubscribe();
        //         }
        //     });
    }

    isAllowedEnv() {
        return environment.env === 'test' || environment.env === 'dev' || environment.env === 'emulator';
    }
}
