import {DEFAULT_CURRENCY_CODE, Injector, NgModule} from '@angular/core';
import {getAnalytics, provideAnalytics} from '@angular/fire/analytics';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';
import {USE_EMULATOR as USE_DATABASE_EMULATOR,} from '@angular/fire/compat/database';
import {USE_EMULATOR as USE_FIRESTORE_EMULATOR,} from '@angular/fire/compat/firestore';
import {getDatabase, provideDatabase,} from '@angular/fire/database';
import {connectFirestoreEmulator, getFirestore, provideFirestore,} from '@angular/fire/firestore';
import {connectFunctionsEmulator, FunctionsModule, getFunctions, provideFunctions,} from '@angular/fire/functions';
import {getStorage, provideStorage,} from '@angular/fire/storage';
import {FormsModule} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE,} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/tooltip';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JwtModule} from '@auth0/angular-jwt';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {NgxPermissionsModule} from 'ngx-permissions';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AUTH_EXPIRATION_TIME, FIREBASE_REGION, INACTIVE_PING_CHECK,} from './app.constants';
import {SystemWarningsModule} from './core/components/check-version/system-warnings.module';
import {SystemWarningsService} from './core/components/check-version/system-warnings.service';
import {InactiveUserModule} from './core/components/inactive-user/inactive-user.module';
import {InfoDialogModule} from './core/components/info-dialog/info-dialog.module';
import {setAppInjector} from './core/util/app.injector';
import {MainAdministrationModule} from './modules/administration/main/main-administration.module';
import {MainCompanyModule} from './modules/company/main/main-company.module';
import {ImprintModule} from './modules/public/internal-pages/imprint/imprint.module';
import {PrivacyModule} from './modules/public/internal-pages/privacy/privacy.module';
import {CurrencyConfig} from './utils/currency-config';
import {APP_DATE_FORMATS, AppDateAdapter} from './utils/date-adapter';
import { OptinsModule } from './modules/company/optins/optins.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        MainCompanyModule,
        MainAdministrationModule,
        NgxSpinnerModule,
        NgxPermissionsModule.forRoot(),
        FunctionsModule,
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => {
            const auth = getAuth();
            // only use when the backend is enabled with auth migration
            // if (environment.env === 'emulator') {
            //     connectAuthEmulator(auth, 'http://localhost:9099', {
            //         disableWarnings: true,
            //     });
            // }
            return auth;
        }),
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideFirestore(() => {
            const firestore = getFirestore();
            if (environment.env === 'emulator') {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            return firestore;
        }),
        provideDatabase(() => {
            const database = getDatabase();
            // only use when the backend is enabled with realtime database migration
            //
            // if (environment.env === 'emulator') {
            //     connectDatabaseEmulator(database, 'localhost', 9000);
            // }
            return database;
        }),
        provideStorage(() => {
            const storage = getStorage();
            // only use when the backend is enabled with storage migration
            // if (environment.env === 'emulator') {
            //     connectStorageEmulator(storage, 'localhost', 9199);
            // }
            return storage;
        }),
        provideFunctions(() => {
            const functions = getFunctions(getApp());
            functions.region = FIREBASE_REGION;
            if (environment.env === 'emulator') {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            return functions;
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return localStorage.getItem('access_token');
                },
            },
        }),
        MatDialogModule,
        TranslateModule,
        InactiveUserModule.forRoot({
            timeout: AUTH_EXPIRATION_TIME,
            ping: INACTIVE_PING_CHECK,
        }),
        SystemWarningsModule,
        NgxDatatableModule,
        InfoDialogModule,
        ImprintModule,
        PrivacyModule,
        NgCircleProgressModule.forRoot({
            radius: 22,
            showSubtitle: false,
            outerStrokeWidth: 5,
            innerStrokeWidth: 1,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
            titleFontSize: '13',
            unitsFontSize: '13',
            titleFontWeight: '600',
            unitsFontWeight: '600',
            outerStrokeLinecap: 'butt',
            space: 2,
            titleColor: '#8C969F',
            unitsColor: '#8C969F',
        }),
        ToastrModule.forRoot({
            timeOut: 4000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            iconClasses: {
                info: 'toast-info',
            },
        }),
        OptinsModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: USE_DATABASE_EMULATOR,
            useValue:
                environment.env === 'emulator'
                    ? ['localhost', 9000]
                    : undefined,
        },
        {
            provide: USE_FIRESTORE_EMULATOR,
            useValue:
                environment.env === 'emulator'
                    ? ['localhost', 8080]
                    : undefined,
        },
        {
            provide: USE_FUNCTIONS_EMULATOR,
            useValue:
                environment.env === 'emulator'
                    ? ['localhost', 5001]
                    : undefined,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'de' },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: REGION, useValue: FIREBASE_REGION },
        MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        { provide: CURRENCY_MASK_CONFIG, useValue: CurrencyConfig },
        {
            provide: USE_DATABASE_EMULATOR,
            useValue:
                environment.env === 'emulator'
                    ? ['localhost', 9000]
                    : undefined,
        },
        {
            provide: USE_FIRESTORE_EMULATOR,
            useValue:
                environment.env === 'emulator'
                    ? ['localhost', 8080]
                    : undefined,
        },
        {
            provide: USE_FUNCTIONS_EMULATOR,
            useValue:
                environment.env === 'emulator'
                    ? ['localhost', 5001]
                    : undefined,
        },
    ],
})
export class AppModule {
    constructor(
        private injector: Injector,
        private dateAdapter: DateAdapter<Date>,
        private translateService: TranslateService,
        private systemWarningsService: SystemWarningsService
    ) {
        setAppInjector(this.injector);
        this.translateService.onLangChange.subscribe((result) => {
            this.dateAdapter.setLocale(result.lang);
        });
        this.systemWarningsService.init();
    }
}
