import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { CompanySideMenuModule } from 'src/app/core/components/company-side-menu/company-side-menu.module';
import { HeaderModule } from 'src/app/core/components/header/header.module';
import { InactiveUserModule } from 'src/app/core/components/inactive-user/inactive-user.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotificationsSideMenuModule } from '../../../shared/components/notifications-side-menu/notifications-side-menu.module';
import { AuthenticationModule } from '../../cross-profile/authentication/authentication.module';
import { MainCompanyComponent } from './main-company.component';
import { ProgressToasterModule } from 'src/app/shared/components/progress-toaster/progress-toaster.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HeaderModule,
        CompanySideMenuModule,
        AuthenticationModule,
        SharedModule,
        MatSidenavModule,
        NotificationsSideMenuModule,
        InactiveUserModule,
        ProgressToasterModule
    ],
    declarations: [MainCompanyComponent],
})
export class MainCompanyModule {}
